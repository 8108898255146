<template>
    <div class="main-container">
        <div class="view-container">
            <!-- <UserBrowseHistoryView v-show="historyViewVisible" />
            <StudySearchView v-show="searchViewVisible" />
            <ConcernedPersonListView v-show="patientListViewVisible" />
            <UserView v-show="myViewVisible" /> -->
            <router-view></router-view>
        </div>
        
        <div class="tool-container" ref="toolContainer">
            <div class="tool-box btn-search active" @click="clickButton(0)">
                <div class="tool-icon"><i class="el-icon-search"></i></div>
                <div class="tool-name">查找检查</div>
            </div>
            <div class="tool-box btn-history" @click="clickButton(1)">
                <div class="tool-icon"><i class="el-icon-timer"></i></div>
                <div class="tool-name">最近浏览</div>
            </div>
            <div class="tool-box btn-patient" @click="clickButton(2)">
                <div class="tool-icon"><i class="el-icon-s-custom"></i></div>
                <div class="tool-name">关心的人</div>
            </div>
            <div class="tool-box btn-my" @click="clickButton(3)">
                <div class="tool-icon"><i class="el-icon-user"></i></div>
                <div class="tool-name">我的</div>
            </div>
        </div>
    </div>
</template>

<script>
// import StudySearchView from './study/StudySearchView.vue'
// import UserBrowseHistoryView from './study/UserBrowseHistoryView.vue'
// import ConcernedPersonListView from './study/ConcernedPersonListView.vue'
// import UserView from './user/UserView.vue'
// import { store } from '../utils/store'

export default {
    name: 'MainView',
    components: {
        // StudySearchView,
        // UserBrowseHistoryView,
        // ConcernedPersonListView,
        // UserView
    },
    data() {
        return {
            historyViewVisible: true,
            searchViewVisible: false,
            patientListViewVisible: false,
            myViewVisible: false
        }
    },
    created() {
        // console.log("MainView created", this.$router.currentRoute.path)
        // this.historyViewVisible = false;
        // this.searchViewVisible = false;
        // this.patientListViewVisible = false;
        // this.myViewVisible = false;
        // if (this.$router.currentRoute.path.endsWith("/recent") == true) {
        //     this.historyViewVisible = true;
        // } else if (this.$router.currentRoute.path.endsWith("/search") == true) {
        //     this.searchViewVisible = true;
        // } else if (this.$router.currentRoute.path.endsWith("/concern") == true) {
        //     this.patientListViewVisible = true;
        //     this.clickButton(2);
        // } else if (this.$router.currentRoute.path.endsWith("/user") == true) {
        //     this.myViewVisible = true;
        // }
    },
    mounted() {
        // 从子页面返回时，设置当前活动标签
        let elToolContainer = this.$refs.toolContainer;
        let activeBtn = elToolContainer.querySelector('.tool-box.active');
        if (activeBtn != null) {
            activeBtn.classList.remove('active');
        }
        if (this.$router.currentRoute.path.endsWith("/recent") == true) {
            let btn = elToolContainer.querySelector('.btn-history');
            btn.classList.add('active');
        } else if (this.$router.currentRoute.path.endsWith("/search") == true) {
            let btn = elToolContainer.querySelector('.btn-search');
            btn.classList.add('active');
        } else if (this.$router.currentRoute.path.endsWith("/concern") == true) {
            let btn = elToolContainer.querySelector('.btn-patient');
            btn.classList.add('active');
        } else if (this.$router.currentRoute.path.endsWith("/user") == true) {
            let btn = elToolContainer.querySelector('.btn-my');
            btn.classList.add('active');
        }
    },
    activated() {
        // console.log("MainView activated")
    },
    methods: {
        clickButton(btnIndex) {
            let elToolContainer = this.$refs.toolContainer;
            let activeBtn = elToolContainer.querySelector('.tool-box.active');
            if (activeBtn != null) {
                activeBtn.classList.remove('active');
            }
            elToolContainer.children[btnIndex].classList.add("active");

            if (2 == btnIndex) {
                let btn = elToolContainer.querySelector('.btn-patient');
                btn.classList.add('active');
                // this.historyViewVisible = false;
                // this.searchViewVisible = false;
                // this.patientListViewVisible = true;
                // this.myViewVisible = false;
                this.$router.replace('concern');
            } else if (1 == btnIndex) {
                let btn = elToolContainer.querySelector('.btn-history');
                btn.classList.add('active');
                // this.historyViewVisible = true;
                // this.searchViewVisible = false;
                // this.patientListViewVisible = false;
                // this.myViewVisible = false;
                this.$router.replace('recent');
            } else if (0 == btnIndex) {
                let btn = elToolContainer.querySelector('.btn-search');
                btn.classList.add('active');
                // this.historyViewVisible = false;
                // this.searchViewVisible = true;
                // this.patientListViewVisible = false;
                // this.myViewVisible = false;
                this.$router.replace('search');
            } else if (3 == btnIndex) {
                let btn = elToolContainer.querySelector('.btn-my');
                btn.classList.add('active');
                // this.historyViewVisible = false;
                // this.searchViewVisible = false;
                // this.patientListViewVisible = false;
                // this.myViewVisible = true;
                this.$router.replace('user');
            }
        }
    }
}
</script>

<style scoped>
    .main-container {
        height: 100%;
        position: relative;
    }
    .view-container {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 15vmin;

        background-color: #f6f6f6;
    }
    .tool-container {
        position: absolute;
        left: 0;
        right: 0;
        top: calc(100% - 15vmin);
        bottom: 0;

        border-top: 1px solid #ddd;
        background-color: #fdfdfd;
        padding: 1vmin 0 0 0;

        display: flex;
    }
    .tool-container .active {
        color: #409eff;
    }
    .tool-container .tool-box {
        width: 25%;
    }
    .tool-container .tool-box .tool-icon {
        font-size: 7vmin;
    }
    .tool-container .tool-box .tool-name {
        font-size: 3vmin;
    }
</style>